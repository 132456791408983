import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Menu from './Menu';

class Job extends Component{
    render(){
        return(
            <>
            <Menu/>
            <div className="job_container">
                <Fade right>
                
                <div className="job_area">
                    <div>
                    <p className="job_area-title">Zatrudniamy</p>
                    <p>Szukamy pracowników budowlanych do pracy w województwie śląskim. Wszystkiego Cię nauczymy, liczą się chęci i motywacja.<br></br><br></br>Jeżeli jesteś zainteresowany skontaktuj się z nami pod numer:<b>+48 502253424.</b>  </p>
                    </div>
                </div>
            </Fade>
            </div>            
            </>
        )
    }
}


export default Job;